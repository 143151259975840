import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import widont from "widont"
import ScrollOut from "scroll-out"
import AnchorLink from "react-anchor-link-smooth-scroll"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import Image from "../../../components/image"
import GatedContent from "../../../components/gatedContent"

import styles from "./octopus-part-2.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
}

const Section = props => {
  const sectionRef = useRef()
  const options = {
    rootMargin: "-20% 0% -80% 0%",
  }

  useEffect(() => {
    const observer = new IntersectionObserver(function callback(entries) {
      entries.forEach(entry => {
        const link =
          typeof document !== "undefined"
            ? document.querySelector(
                `a.js-nav-link[href="#${sectionRef.current.id}"]`
              )
            : null

        if (entry.isIntersecting) {
          link.classList.add(styles.isActive)
        } else {
          link.classList.remove(styles.isActive)
        }
      })
    }, options)

    observer.observe(sectionRef.current)

    return () => {
      observer.disconnect()
    }
  })

  return (
    <div {...props} ref={sectionRef}>
      {props.children}
    </div>
  )
}

const Article = ({ data }) => {
  // Images
  const SensingIcon1Shift = data.craft.SensingIcon1Shift
  const SensingIcon2Practices = data.craft.SensingIcon2Practices
  const SensingImage1Top = data.craft.SensingImage1Top
  const SensingImage2Bottom = data.craft.SensingImage2Bottom
  const SensingThumbnail = data.craft.SensingThumbnail
  const DecentralizedIcon1Shift = data.craft.DecentralizedIcon1Shift
  const DecentralizedIcon2Practices = data.craft.DecentralizedIcon2Practices
  const DecentralizedImage1Top = data.craft.DecentralizedImage1Top
  const DecentralizedImage2Bottom = data.craft.DecentralizedImage2Bottom
  const DecentralizedThumbnail = data.craft.DecentralizedThumbnail
  const AdaptabilityIcon1Shift = data.craft.AdaptabilityIcon1Shift
  const AdaptabilityIcon2Practices = data.craft.AdaptabilityIcon2Practices
  const AdaptabilityImage1Top = data.craft.AdaptabilityImage1Top
  const AdaptabilityImage2Bottom = data.craft.AdaptabilityImage2Bottom
  const AdaptabilityThumbnail = data.craft.AdaptabilityThumbnail
  const FutureFocusedIcon1Shift = data.craft.FutureFocusedIcon1Shift
  const FutureFocusedIcon2Practices = data.craft.FutureFocusedIcon2Practices
  const FutureFocusedImage1Top = data.craft.FutureFocusedImage1Top
  const FutureFocusedImage2Bottom = data.craft.FutureFocusedImage2Bottom
  const FutureFocusedThumbnail = data.craft.FutureFocusedThumbnail
  // Page Data
  const article = data.craft.article
  const seo = article.seomatic
  const coverImages = article.coverImages ? article.coverImages[0] : null

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      cssProps: {
        elementHeight: true,
        viewportY: true,
        visibleY: true,
        offsetY: true,
        intersectY: true,
        scrollPercentY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody}>
            <div className="wrapper">
              <div className={styles.intro}>
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        Late last year, my fellow Joneses and I were struggling
                        with a problem we saw across business: How does a
                        company operate more fluidly? Consumers want companies
                        to match the rapid pace of culture and to evolve their
                        offerings at that same speed. The companies who want to
                        succeed must be hyper-adaptive, interactive and
                        progressive. But most companies have rigid structures
                        with rigid operating models. So we looked to the natural
                        world for a different model and found an unexpected
                        mentor—the octopus. Octopuses exemplify operationalized
                        fluidity; they’re adaptive, strong, efficient and always
                        what they need to be in that exact moment.
                      </p>
                      <p>
                        When we landed on the Octopus as the model, we knew we
                        were on to something, but we had no idea how essential
                        it would become in just a few months. Today, more than
                        ever, we recognize the need to have fluidity built into
                        how everyone works. COVID-19 has shown us many places
                        where the underlying infrastructure was thought to be
                        strong but was actually quite weak, and now it's
                        struggling to survive.
                      </p>
                      <p>
                        Companies are now all recognizing a need to adapt their
                        structures in the short-term while still being proactive
                        about the future. Many are hoping to wait out the issues
                        while others are attempting to answer these needs with
                        new processes and mechanics. But you can't build a
                        process to cope with the unknown.
                      </p>
                      <p>
                        That’s the crux of why the Octopus model is essential—we
                        humans can't seem to adapt to emergent change fast
                        enough or with an eye to the future, but Octopuses have
                        evolved in relation to uncertainty. They can not only
                        adapt to a given moment but can come out better on the
                        other side.
                      </p>
                      <p>This is the model we should all strive to create.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-7-desktop">
                    <h2 className={styles.headline}>
                      There are <span>4</span> key attributes to the octopus
                      model.
                    </h2>
                  </div>
                </div>
              </div>
              <div className={styles.jumpWrapper}>
                <ul className="columns is-tablet is-centered is-multiline">
                  <li className="column is-6-tablet is-5-desktop text-center">
                    <AnchorLink href="#sensing" className={styles.jump}>
                      <Image
                        url={SensingThumbnail.url}
                        width={SensingThumbnail.width}
                        height={SensingThumbnail.height}
                        alt={SensingThumbnail.altText}
                        sizes="200px"
                      />
                      <p className={styles.jumpTitle}>
                        <span>Sensing</span>
                      </p>
                      <div className={styles.jumpBody}>
                        <p>
                          {widont(
                            `Devotion to taking in the world around you.`
                          )}
                        </p>
                      </div>
                    </AnchorLink>
                  </li>
                  <li className="column is-6-tablet is-5-desktop text-center">
                    <AnchorLink href="#decentralized" className={styles.jump}>
                      <Image
                        url={DecentralizedThumbnail.url}
                        width={DecentralizedThumbnail.width}
                        height={DecentralizedThumbnail.height}
                        alt={DecentralizedThumbnail.altText}
                        sizes="200px"
                      />
                      <p className={styles.jumpTitle}>
                        <span>Decentralized</span>
                      </p>
                      <div className={styles.jumpBody}>
                        <p>
                          {widont(
                            `Communicating seamlessly throughout an organization.`
                          )}
                        </p>
                      </div>
                    </AnchorLink>
                  </li>
                  <li className="column is-6-tablet is-5-desktop text-center">
                    <AnchorLink href="#adaptability" className={styles.jump}>
                      <Image
                        url={AdaptabilityThumbnail.url}
                        width={AdaptabilityThumbnail.width}
                        height={AdaptabilityThumbnail.height}
                        alt={AdaptabilityThumbnail.altText}
                        sizes="200px"
                      />
                      <p className={styles.jumpTitle}>
                        <span>Adaptability</span>
                      </p>
                      <div className={styles.jumpBody}>
                        <p>
                          {widont(`Incentivizing progress over efficiency.`)}
                        </p>
                      </div>
                    </AnchorLink>
                  </li>
                  <li className="column is-6-tablet is-5-desktop text-center">
                    <AnchorLink href="#future" className={styles.jump}>
                      <Image
                        url={FutureFocusedThumbnail.url}
                        width={FutureFocusedThumbnail.width}
                        height={FutureFocusedThumbnail.height}
                        alt={FutureFocusedThumbnail.altText}
                        sizes="200px"
                      />
                      <p className={styles.jumpTitle}>
                        <span>Future-focused</span>
                      </p>
                      <div className={styles.jumpBody}>
                        <p>
                          {widont(
                            `Planning long-term but reacting to the short-term.`
                          )}
                        </p>
                      </div>
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {/* .wrapper */}
              <Section
                id="sensing"
                className={`${styles.section} ${styles.bgBrown}`}
                data-scroll=""
              >
                <div className="wrapper">
                  <div className="columns is-desktop">
                    <div className="column is-4-desktop">
                      <div className={styles.sectionSidebarImages}>
                        <div>
                          <div
                            className={`${styles.sectionSidebarImageBack} ${styles.sectionSidebarImageMobile}`}
                          >
                            <Image
                              url={SensingImage1Top.url}
                              width={SensingImage1Top.width}
                              height={SensingImage1Top.height}
                              alt={SensingImage1Top.altText}
                              sizes="420px"
                            />
                          </div>
                          <div className={`${styles.sectionSidebarImageFront}`}>
                            <Image
                              url={SensingImage2Bottom.url}
                              width={SensingImage2Bottom.width}
                              height={SensingImage2Bottom.height}
                              alt={SensingImage2Bottom.altText}
                              sizes="281px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6-desktop is-offset-1-desktop">
                      <div className={styles.sectionIntro}>
                        <h2 className={styles.sectionTitle}>Sensing</h2>
                        <p>
                          The octopus senses the environment and then adapts
                          their response. Their 8 arms and over 1,500 suckers
                          are always independently and constantly sensing,
                          monitoring, listening and potentially even seeing.
                          They can also have instant coordinated responses in
                          reaction to threats or changes in their environment.
                          But businesses often have one or more elements of
                          insights and analytics that are centralized for
                          efficiency. And the response to change is very slow,
                          often lacking any sort of coordinated support.
                        </p>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={SensingIcon1Shift.url}
                          width={SensingIcon1Shift.width}
                          height={SensingIcon1Shift.height}
                          alt={SensingIcon1Shift.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          The key shifts for business
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Always-on insights</h4>
                          <p>
                            Insights aren’t “commissioned,” and sensing happens
                            constantly against multiple different environmental
                            dimensions.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Fast, structured communication
                          </h4>
                          <p>
                            Critical information is relayed immediately to the
                            rest of the organization. Not all insight needs to
                            be shared.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Built-in redundancy & flexibility
                          </h4>
                          <p>
                            Different parts of the organization have the
                            capacity and capability to shift to supporting
                            others immediately.
                          </p>
                        </div>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={SensingIcon2Practices.url}
                          width={SensingIcon2Practices.width}
                          height={SensingIcon2Practices.height}
                          alt={SensingIcon2Practices.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          Practices Zeus Jones has adopted
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Specialty Marketplace</h4>
                          <p>
                            We created an open-source resource model that helps
                            us access folks with specific vertical skills.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Vertical and horizontal skills
                          </h4>
                          <p>
                            We've all outlined our vertical skills (expertise
                            leadership) and horizontal skills (project
                            leadership).
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Distributed leadership and responsibility
                          </h4>
                          <p>
                            We've expanded our partnership team to 11, and
                            created teams around key jobs to be done within our
                            business flywheel.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Working out loud and creating a metabolism
                          </h4>
                          <p>
                            We're always doubling down on transparent
                            communication has meant less duplicated work and
                            faster progress.
                          </p>
                        </div>
                      </div>
                      <AnchorLink className={styles.workbook} href="#form">
                        Download the workbook
                      </AnchorLink>
                    </div>
                  </div>
                </div>
                {/* .wrapper */}
              </Section>
              {/* .section.bg-brown */}
              {/* .wrapper */}
              <Section
                id="decentralized"
                className={`${styles.section} ${styles.bgPink}`}
                data-scroll=""
              >
                <div className="wrapper">
                  <div className="columns is-desktop">
                    <div className="column is-4-desktop">
                      <div className={styles.sectionSidebarImages}>
                        <div>
                          <div className={`${styles.sectionSidebarImageBack}`}>
                            <Image
                              url={DecentralizedImage1Top.url}
                              width={DecentralizedImage1Top.width}
                              height={DecentralizedImage1Top.height}
                              alt={DecentralizedImage1Top.altText}
                              sizes="420px"
                            />
                          </div>
                          <div
                            className={`${styles.sectionSidebarImageFront} ${styles.sectionSidebarImageMobile}`}
                          >
                            <Image
                              url={DecentralizedImage2Bottom.url}
                              width={DecentralizedImage2Bottom.width}
                              height={DecentralizedImage2Bottom.height}
                              alt={DecentralizedImage2Bottom.altText}
                              sizes="281px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6-desktop is-offset-1-desktop">
                      <div className={styles.sectionIntro}>
                        <h2 className={styles.sectionTitle}>
                          Decentralized Intelligence
                        </h2>
                        <p>
                          What makes the octopus so extraordinary is its nervous
                          system. Most of its neurons are in the tentacles. For
                          humans, it's hard to conceptualize a kind of
                          intelligence in which the head plays a secondary role:
                          What would it be like to think with your arms? All
                          corporations protectively centralize their
                          intelligence. From bureaucratic, hierarchical
                          structures to the relentless protection of corporate
                          information and IP, companies are fundamentally built
                          to centralize the intelligence that matters.
                        </p>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={DecentralizedIcon1Shift.url}
                          width={DecentralizedIcon1Shift.width}
                          height={DecentralizedIcon1Shift.height}
                          alt={DecentralizedIcon1Shift.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          The key shifts for business
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Independent intelligence at the edges
                          </h4>
                          <p>
                            The latest, most strategic and progressive thinking
                            is done at the edges of the company. Not in
                            ivory-tower isolation, but in conjunction with the
                            environment.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Centralized coordination</h4>
                          <p>
                            The center operates as a nervous system rather than
                            a brain, intelligently coordinating and
                            communicating with all parts of the organization.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Visibility across entire organization
                          </h4>
                          <p>
                            Rather than focusing on communication across the
                            org, aim for transparency. Intelligent edges must be
                            able to access information from anywhere when they
                            need it.
                          </p>
                        </div>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={DecentralizedIcon2Practices.url}
                          width={DecentralizedIcon2Practices.width}
                          height={DecentralizedIcon2Practices.height}
                          alt={DecentralizedIcon2Practices.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          Practices Zeus Jones has adopted
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Innovation on every project
                          </h4>
                          <p>
                            Zeus Jones never approaches a project the same way
                            twice. We're always building on our IP, rather than
                            guarding it and treating it as sacred.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Athena</h4>
                          <p>
                            We created a platform for thinking and
                            experimentation that's guided by our own interests.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Ops model built around IP</h4>
                          <p>
                            We've built our project ops models around our IP and
                            how we're adding to it and sharing what we've
                            learned back to the organization.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Pallas</h4>
                          <p>
                            Everyone at Zeus has access to all of our IP,
                            research, and thinking through Pallas—and you're
                            expected to build upon this body of work.
                          </p>
                        </div>
                      </div>
                      <AnchorLink className={styles.workbook} href="#form">
                        Download the workbook
                      </AnchorLink>
                    </div>
                  </div>
                </div>
                {/* .wrapper */}
              </Section>
              {/* .section.bg-pink */}
              {/* .wrapper */}
              <Section
                id="adaptability"
                className={`${styles.section} ${styles.bgBlue}`}
                data-scroll=""
              >
                <div className="wrapper">
                  <div className="columns is-desktop">
                    <div className="column is-4-desktop">
                      <div className={styles.sectionSidebarImages}>
                        <div>
                          <div
                            className={`${styles.sectionSidebarImageBack} ${styles.sectionSidebarImageMobile}`}
                          >
                            <Image
                              url={AdaptabilityImage1Top.url}
                              width={AdaptabilityImage1Top.width}
                              height={AdaptabilityImage1Top.height}
                              alt={AdaptabilityImage1Top.altText}
                              sizes="420px"
                            />
                          </div>
                          <div className={`${styles.sectionSidebarImageFront}`}>
                            <Image
                              url={AdaptabilityImage2Bottom.url}
                              width={AdaptabilityImage2Bottom.width}
                              height={AdaptabilityImage2Bottom.height}
                              alt={AdaptabilityImage2Bottom.altText}
                              sizes="281px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6-desktop is-offset-1-desktop">
                      <div className={styles.sectionIntro}>
                        <h2 className={styles.sectionTitle}>Adaptability</h2>
                        <p>
                          Adaptability and fluidity are superpowers of the
                          octopus. Octopuses are well known for their impressive
                          survival skills—from their ability to fit into
                          impossibly small spaces and regrow lost limbs, to
                          their quick camouflage capabilities and ink that
                          clouds predators’ senses of sight and smell. Companies
                          are fundamentally built for rigidity. Regardless of
                          organizational model, companies tend to be extremely
                          fixed and built for stability. But those structures
                          are often in stark contrast to the pace of change most
                          companies must endure today.
                        </p>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={AdaptabilityIcon1Shift.url}
                          width={AdaptabilityIcon1Shift.width}
                          height={AdaptabilityIcon1Shift.height}
                          alt={AdaptabilityIcon1Shift.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          The key shifts for business
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Optimize against progress, not efficiency
                          </h4>
                          <p>
                            Align incentives, measurement, and operations around
                            tangible output and progress rather than efficiency.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Prioritize connection over communication
                          </h4>
                          <p>
                            Communication is slow and transactional.
                            Organizations must prioritize connection instead. A
                            common purpose, language and operating system that
                            keeps everyone in sync.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Speed the organizational metabolism
                          </h4>
                          <p>
                            Focus and shrink initiatives so that they can be
                            completed quickly. Break down big transformation
                            into a set of smaller, faster moves. Increase the
                            pace.
                          </p>
                        </div>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={AdaptabilityIcon2Practices.url}
                          width={AdaptabilityIcon2Practices.width}
                          height={AdaptabilityIcon2Practices.height}
                          alt={AdaptabilityIcon2Practices.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          Practices Zeus Jones has adopted
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">New ways of working</h4>
                          <p>
                            We’re constantly figuring out the smartest way to
                            work. Recently that's meant smaller core teams and
                            organically pulling in "verticals"—specific
                            expertise—when needed.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Jobs to be done</h4>
                          <p>
                            Instead of organizing around departments, we
                            organize around jobs to be done. For us, that means
                            a group around creativity and thought leadership, a
                            group around talent, and one around expanding
                            opportunities.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Values as a decision-making framework
                          </h4>
                          <p>
                            Our values and purpose guide both day-to-day and
                            long-term decisions. They act as a filter for
                            everyone at the company and help ensure we make the
                            right choices.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Discomfort with stability</h4>
                          <p>
                            One of the first things we tell candidates is that
                            this is not a place for people who want to do the
                            same thing twice. You must be comfortable being
                            uncomfortable—and even have a preference for
                            discomfort.
                          </p>
                        </div>
                      </div>
                      <AnchorLink className={styles.workbook} href="#form">
                        Download the workbook
                      </AnchorLink>
                    </div>
                  </div>
                </div>
                {/* .wrapper */}
              </Section>
              {/* .section.bg-blue */}
              {/* .wrapper */}
              <Section
                id="future"
                className={`${styles.section} ${styles.bgPurple}`}
                data-scroll=""
              >
                <div className="wrapper">
                  <div className="columns is-desktop">
                    <div className="column is-4-desktop">
                      <div className={styles.sectionSidebarImages}>
                        <div>
                          <div
                            className={`${styles.sectionSidebarImageBack} ${styles.sectionSidebarImageMobile}`}
                          >
                            <Image
                              url={FutureFocusedImage1Top.url}
                              width={FutureFocusedImage1Top.width}
                              height={FutureFocusedImage1Top.height}
                              alt={FutureFocusedImage1Top.altText}
                              sizes="420px"
                            />
                          </div>
                          <div className={`${styles.sectionSidebarImageFront}`}>
                            <Image
                              url={FutureFocusedImage2Bottom.url}
                              width={FutureFocusedImage2Bottom.width}
                              height={FutureFocusedImage2Bottom.height}
                              alt={FutureFocusedImage2Bottom.altText}
                              sizes="281px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6-desktop is-offset-1-desktop">
                      <div className={styles.sectionIntro}>
                        <h2 className={styles.sectionTitle}>Future-focused</h2>
                        <p>
                          Octopuses are thriving as a result of climate change.
                          They have a unique set of biological traits—such as
                          rapid growth and short lifespans—that allow them to
                          adapt quickly to changing conditions. Even in good
                          times, companies were dying younger and at a much
                          faster pace. A recent study by McKinsey found that the
                          average life-span of companies listed in Standard &
                          Poor's 500 was 61 years in 1958. Today, it is less
                          than 18 years. McKinsey believes that, in 2027, 75% of
                          the companies currently quoted on the S&P 500 will
                          have disappeared.
                        </p>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={FutureFocusedIcon1Shift.url}
                          width={FutureFocusedIcon1Shift.width}
                          height={FutureFocusedIcon1Shift.height}
                          alt={FutureFocusedIcon1Shift.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          The key shifts for business
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Adopt a long-term mindset</h4>
                          <p>
                            Report quarterly but plan against generation-long
                            time periods.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Create the future</h4>
                          <p>
                            Don’t simply study and serve existing demand.
                            Actively envision and build toward a future that you
                            can own.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Think cooperation over competition
                          </h4>
                          <p>
                            Establish your role within an ecosystem of
                            businesses and align your success and survival with
                            the success and survival of all actors within the
                            ecosystem.
                          </p>
                        </div>
                      </div>
                      <div className={styles.sectionList}>
                        <Image
                          className={styles.sectionListIcon}
                          url={FutureFocusedIcon2Practices.url}
                          width={FutureFocusedIcon2Practices.width}
                          height={FutureFocusedIcon2Practices.height}
                          alt={FutureFocusedIcon2Practices.altText}
                          sizes="48px"
                        />
                        <h3 className={styles.sectionListHeading}>
                          Practices Zeus Jones has adopted
                        </h3>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Trajectory planning</h4>
                          <p>
                            We've looked at multiple horizons—different cultural
                            scenarios over 5, 10, and 15 years based on our
                            research. We've started to plan numerous possible
                            trajectories for our business against these
                            scenarios.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Investing in and practicing imagination
                          </h4>
                          <p>
                            We relentlessly push ourselves to be more ambitious.
                            We've invested in staff cultural tours and learning
                            programs for all Joneses to continue to bring fresh
                            inspiration.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">
                            Fixed values, fluid process
                          </h4>
                          <p>
                            We don't have a single way of operating. Instead, we
                            have fluid cultural norms and use every project as a
                            stepping stone to the next one. We're guided by our
                            values, not our process.
                          </p>
                        </div>
                        <div className={styles.sectionListItem}>
                          <h4 className="fw-bold">Nostos</h4>
                          <p>
                            We're independent and not part of a holding company.
                            But we know our ecosystem matters. So we launched a
                            referral community of best-in-class indie agencies
                            who offer capabilities that are complementary to our
                            own, collaborating with them on projects that fall
                            outside of our typical wheelhouse.{" "}
                            <a
                              className="link-grow"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.nostos.network/"
                            >
                              See Nostos
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                      <AnchorLink className={styles.workbook} href="#form">
                        Download the workbook
                      </AnchorLink>
                    </div>
                  </div>
                </div>
                {/* .wrapper */}
              </Section>
              {/* .section.bg-purple */}
              {/*
                Sticky nav container
                Hidden since there's nav at the beginning
              */}
              <div aria-hidden={true} className={styles.nav}>
                <div className="wrapper">
                  <ul>
                    <li>
                      <AnchorLink
                        href="#sensing"
                        className={`${styles.jump} js-nav-link`}
                      >
                        Sensing
                      </AnchorLink>
                    </li>
                    <li>
                      <AnchorLink
                        href="#decentralized"
                        className={`${styles.jump} js-nav-link`}
                      >
                        Decentralized Intelligence
                      </AnchorLink>
                    </li>
                    <li>
                      <AnchorLink
                        href="#adaptability"
                        className={`${styles.jump} js-nav-link`}
                      >
                        Adaptability
                      </AnchorLink>
                    </li>
                    <li>
                      <AnchorLink
                        href="#future"
                        className={`${styles.jump} js-nav-link`}
                      >
                        Future-focused
                      </AnchorLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Sticky nav container end */}
            {/* Form */}
            <div id="form" className="wrapper">
              <div className="columns is-centered is-tablet">
                <div className="column is-10-tablet is-7-desktop">
                  <div className={styles.form}>
                    <div className="mb-2">
                      <h2 className={styles.formTitle}>
                        {widont(`Want to apply this model to your business?`)}
                      </h2>
                    </div>
                    <div className={`${styles.formBody} body`}>
                      <p>
                        We’ve created a workbook to help. Fill out the form
                        below, and we’ll send it right over.
                      </p>
                    </div>
                    <GatedContent
                      uri={article.uri}
                      formId="f7120208-8a44-41c6-9f55-787e58076265"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* .wrapper */}
            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <div className={styles.credits}>
              <div className="wrapper">
                <p>
                  Illustrations courtesy of{" "}
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.flickr.com/photos/biodivlibrary/sets/ "
                  >
                    Biodiversity Heritage Library
                  </a>
                  ,{" "}
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.rawpixel.com/category/53/public-domain"
                  >
                    Rawpixel LTD
                  </a>
                  , and{" "}
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://commons.wikimedia.org/wiki/Main_Page"
                  >
                    Wikipedia Commons
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      contact: entry(slug: "contact") {
        uri
      }
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        uri
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      SensingIcon1Shift: asset(id: 16101) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      SensingIcon2Practices: asset(id: 16102) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      SensingImage1Top: asset(id: 16103) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      SensingImage2Bottom: asset(id: 16104) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      SensingThumbnail: asset(id: 16105) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      DecentralizedIcon1Shift: asset(id: 16106) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      DecentralizedIcon2Practices: asset(id: 16107) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      DecentralizedImage1Top: asset(id: 16108) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      DecentralizedImage2Bottom: asset(id: 16109) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      DecentralizedThumbnail: asset(id: 16110) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      AdaptabilityIcon1Shift: asset(id: 16111) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      AdaptabilityIcon2Practices: asset(id: 16112) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      AdaptabilityImage1Top: asset(id: 16113) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      AdaptabilityImage2Bottom: asset(id: 16114) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      AdaptabilityThumbnail: asset(id: 16115) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      FutureFocusedIcon1Shift: asset(id: 16116) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      FutureFocusedIcon2Practices: asset(id: 16117) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      FutureFocusedImage1Top: asset(id: 16118) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      FutureFocusedImage2Bottom: asset(id: 16119) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      FutureFocusedThumbnail: asset(id: 16120) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`
